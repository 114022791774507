//colors
$black: #000000;
$white: #fff;
$grey: #cbcfd3;
$light-grey: #f5f6f7;

.buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.footer-btn {
  margin-bottom: 20px;
  margin-right: 20px;
}

//button styles

//default button
.btn {
  color: $black;
  cursor: pointer;
  // display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 48px;
  max-width: 160px;
  position: relative;
  text-decoration: none;
  width: 100%;

  //   @media(min-width: 400px) {
  //     display: inline-block;
  //     margin-right: 2.5em;

  //     &:nth-of-type(even) {
  //       margin-right: 0;
  //     }
  //   }

  @media (min-width: 600px) {
    //     &:nth-of-type(even) {
    //       margin-right: 2.5em;
    //     }

    //     &:nth-of-type(5) {
    //       margin-right: 0;
    //     }
  }

  &:hover {
    text-decoration: none;
  }
}

/////////////////////////////////
//button one
///////////////////////////////

.btn-1 {
  background: darken($light-grey, 1.5%);
  font-weight: 100;
  box-shadow: none !important;

  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  rect {
    fill: none;
    stroke: $black;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.btn-1:hover {
  background: rgba($light-grey, 0);
  font-weight: 400;
  letter-spacing: 1px;

  rect {
    stroke-width: 2;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

//#0068FF

.btn-beta {
  background: $white;
  font-weight: 100;
  width: 150;
  box-shadow: none;
  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  rect {
    fill: none;
    stroke: $black;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.btn-beta:hover {
  background: $white;
  font-weight: 400;
  letter-spacing: 1px;
  stroke: $black;
  color: $black !important;

  rect {
    stroke-width: 2;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

////////////////////////////
//button two
//////////////////////////

.btn-2 {
  background: darken($black, 1.5%);
  font-weight: 100;
  color: $white;
  text-align: center;

  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  rect {
    fill: none;
    stroke: $white;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.btn-2:hover {
  background: $black !important;
  font-weight: 400;
  letter-spacing: 1px;
  color: $white !important;

  rect {
    stroke-width: 2;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

/////////////////////////////
//button -3
///////////////////////////

.btn-3 {
  background: darken($black, 1.5%);
  font-weight: 100;

  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  rect {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.btn-3:hover {
  background: rgba($black, 0);
  font-weight: 900;
  letter-spacing: 1px;

  rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

/////////////////////////////
//button-4
///////////////////////////

.btn-4 {
  background: darken($black, 1.5%);
  font-weight: 100;

  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  rect {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.btn-4:hover {
  background: rgba($black, 0);
  font-weight: 900;
  letter-spacing: 1px;

  rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
