html
  body
  div#root
  div#main
  div.pairs-container.container
  div.pairs-row.row
  div.col-sm-12 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pairs-section {
  /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    padding-bottom: 100px;
  }

  /* ----------- iPhone 6, 6S, 7 and 8 ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    padding-bottom: 50px;
  }

  /* ----------- iPhone 6+, 7+ and 8+ ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    padding-bottom: 50px;
  }

  /* ----------- iPhone X ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    padding-bottom: 50px;
  }

  /* ----------- iPad 1, 2, Mini and Air ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    margin-bottom: 150px;
  }

  /* ----------- iPad 3, 4 and Pro 9.7" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    margin-bottom: 150px;
  }

  /* ----------- iPad Pro 10.5" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-width: 834px) and (max-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    margin-bottom: 200px;
  }

  /* ----------- iPad Pro 12.9" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    margin-bottom: 200px;
  }

  @media only screen and (min-width: 1400px) {
    margin-top: 150px;
    margin-bottom: 250px;
  }
}

.pairs-section p.comparison-blurb {
  max-width: 1400px;
  width: 90%;
  padding-bottom: 65px;

  @media only screen and (max-device-width: 800px) {
    width: 90%;
  }
}

.pairs-container {
  max-width: 1400px;
  width: 90% !important;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (max-width: 1024px) {
    width: 90% !important;
  }
}

.pair-col {
  max-height: 175px;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 40px;
  }
}

.pairs-row {
  @media only screen and (min-width: 1024px) {
    margin-bottom: 40px;
  }
}

.pair-name {
  text-align: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

html
  body
  div#root
  div#main
  section.pairs-section
  div.pairs-container
  div.pairs-row.row
  div.pair-col
  div.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.widget-item {
  margin-left: auto;
  margin-right: auto;
}
