.comparison-section {
  width: 90%;
  margin-right: auto !important;
  margin-left: auto !important;
}

.comparison-container {
  /* min-height: 100vh; */
  background-position: 0px -30vh;
  background-repeat: no-repeat;
  /* background-image: radial-gradient(
      50% 50%,
      rgba(33, 114, 229, 0.1) 0%,
      rgba(33, 36, 41, 0) 100%
    );
    background-color: rgb(44, 47, 54); */
  /* margin-top: 100px; */
  padding-top: 200px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.comparison-sec-titles {
  margin-bottom: 250px !important;
}

.comparison-box {
  /* position: relative; */
  /* max-width: 420px; */
  width: 100%;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  border-radius: 30px;
  /* margin-bottom: 2rem !important; */
  /* padding-right: 1.5rem;
    padding-left: 1.5rem; */
  /* padding:1rem; */
  padding-top: 1.5rem !important;
}
.percentage {
  font-family: "Inter var", sans-serif;
}
.title-text {
  color: black;
  padding-top: 1.5rem;
  font-family: "Inter var", sans-serif;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-box {
  margin-top: "100px";
  padding-left: "3rem";
  padding-right: "3rem";
}

@media only screen and (max-width: 912px) {
  .data-box {
    margin-top: 2.5rem !important;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

@media only screen and (max-width: 1024px) {
  .render-fees {
    padding-top: 500px !important;
  }
  .desktop {
    display: none !important;
  }
  #developers {
    margin-top: -500px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile {
    display: none !important;
  }
}

.extraClass {
  pointer-events: auto !important;
  background: #fff !important;
  opacity: 1 !important;
}
.extraClass:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.comparison-entry {
  border-radius: 20px;
  border: 1px solid rgb(247, 248, 250) !important;
  /* background-color: rgb(33, 36, 41); */
  margin-top: 1rem;
  padding: 1rem !important;
  /* padding-left: 1rem; */
}

.field-input {
  font-family: "Inter var", sans-serif !important;
  color: black !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  border-bottom: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.from-selection {
  -webkit-box-align: center;
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  background-color: white;
  color: black;
  border-radius: 12px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0px 0.5rem;
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
}

.rates-box {
  padding: 18px;
  width: 100%;
  text-align: center;
  border-radius: 20px;
  outline: none;
  border: 1px solid transparent;
  text-decoration: none;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;

  background-color: rgba(32, 169, 188, 0.25);
  color: #20a9bc;
  font-size: 16px;
  font-weight: 500;
}

.rate-text {
  color: #20a9bc;
}

.subtitle-text {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 14px;
  color: rgb(86, 90, 105);
  font-family: "Inter var", sans-serif;
  text-align: left;
}

.selection-col {
  margin-top: 2.5rem;
}

.choose-dropdown {
  background: white !important;
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  border-radius: 30px !important;
}

.assetChoice:hover {
  background-color: rgba(32, 169, 188, 0.15);
  border-radius: 30px;
}

.chosen-asset {
  -webkit-box-align: center;
  align-items: center;
  /* height: 2.2rem; */
  font-size: 20px;
  font-weight: 500;
  /* background-color: rgb(33, 36, 41); */
  color: black;
  border-radius: 12px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  /* padding: 0px 0.5rem; */
  font-family: "Inter var", sans-serif;
}

.asset-logo {
  width: 25px;
}

.comparison-text-container {
  /* margin-top: 4rem;
    margin-bottom: 8rem; */
  /* margin: 0; */
  width: 100%;
  /* position: absolute; */
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.chain-logo {
  height: 75px;
  margin-bottom: 1.5rem !important;
}

.redValue {
  background-color: rgba(255, 66, 72, 0.15);
  color: rgb(255, 66, 72) !important;
  border-radius: 30px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 600;
}

.greenValue {
  background-color: rgba(0, 206, 8, 0.15);
  color: rgb(0, 206, 8) !important;
  border-radius: 30px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 600;
}

.inner-container {
  max-width: 1600px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
