/*======================================================
                   Header (Navbar)
  ======================================================*/

#navbar {
  background: white;
  color: black;
  position: fixed;
  top: 0;
  height: 60px;
  line-height: 60px;
  width: 100%;
  z-index: 10;
  box-shadow: none;
}

.nav-wrapper {
  margin: auto;
  text-align: center;
  max-width: 1400px;
}

@media only screen and (min-width: 801px) {
  .nav-wrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .nav-wrapper {
    width: 90%;
  }
}

.logo img {
  float: left;
  margin-top: 10px;
  height: 40px;
  width: auto;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.overlay-logo img {
  height: 20px;
  width: auto;
}

.overlay-logo {
  position: fixed;
  top: 20px;
  left: 5%;
}

#navbar ul {
  float: right;
  list-style: none;
  /* margin-right: 14px; */
  margin-top: -2px;
  text-align: right;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
}
@media only screen and (max-width: 1024px) {
  #navbar ul {
    display: none;
  }
}

#navbar li {
  display: inline-block;
}

.menu-btn {
  position: fixed;
  right: 5%;
  top: 25px;
}

#navbar li a {
  color: black;
  display: block;
  font-size: 0.8em;
  height: 50px;
  letter-spacing: 1px;
  margin: 0 20px;
  padding: 0 4px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

#navbar li a:hover {
  /* border-bottom: 1px solid rgb(28, 121, 184); */
  color: black;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

/* Animated Bottom Line */
#navbar li a:before,
#navbar li a:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: -1px;
  background: black;
}

#navbar li a:before {
  left: 0;
  transition: 0.5s;
}

#navbar li a:after {
  background: black;
  right: 0;
  /* transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1); */
}

#navbar li a:hover:before {
  background: black;
  width: 100%;
  transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
}

#navbar li a:hover:after {
  background: transparent;
  width: 100%;
  /* transition: 0s; */
}

/* Mobile Menu Icon */
@media only screen and (max-width: 1024px) {
  .menuIcon,
  #menuIcon {
    cursor: pointer;
    display: block;
    position: fixed;
    right: 2%;
    top: 0;
    height: 60px;
    width: 60px;
    z-index: 12;
  }

  /* Icon Bars */
  .icon-bars {
    background: black;
    position: absolute;
    top: 45%;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    right: 5%;
  }

  .icon-bars::before {
    background: black;
    content: "";
    position: absolute;
    right: 5%;
    top: -8px;
    height: 2px;
    width: 20px;
    /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  .icon-bars::after {
    margin-top: 0px;
    background: black;
    content: "";
    position: absolute;
    right: 5%;
    bottom: -8px;
    height: 2px;
    width: 20px;
    /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  /* Bars Shadows */
  .icon-bars.overlay {
    background: #999999;
    background: #cbcfd3;
    width: 20px;
    animation: middleBar 3s infinite 0.5s;
    -webkit-animation: middleBar 3s infinite 0.5s;
  }
  @keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::before {
    background: #999999;
    background: #cbcfd3;
    width: 10px;
    animation: topBar 3s infinite 0.2s;
    -webkit-animation: topBar 3s infinite 0s;
  }
  @keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::after {
    background: #999999;
    background: #cbcfd3;
    width: 15px;
    animation: bottomBar 3s infinite 1s;
    -webkit-animation: bottomBar 3s infinite 1s;
  }
  @keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }

  /* Toggle Menu Icon */
  .menuIcon.toggle .icon-bars {
    top: 5px;
    transform: translate3d(0, 5px, 0) rotate(135deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars::before {
    top: 0;
    transition-delay: 0.1s;
    opacity: 0;
  }

  .menuIcon.toggle .icon-bars::after {
    top: 10px;
    transform: translate3d(0, -10px, 0) rotate(-270deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars.overlay {
    width: 20px;
    opacity: 0;
    -webkit-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
  }
}

#pairs {
  height: 100vh;
}

/* Mobile Menu */

.mobileMenu {
  background: #000000;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-100%);
  width: 100vw;
  height: 100vh;
  -webkit-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
}

.mobileMenu ul,
.mobileMenu li {
  display: block;
  position: relative;
}

.mobileMenu li a {
  display: block;
  font-size: 1em;
  letter-spacing: 4px;
  /*   opacity: 0; */
  padding: 10px 0;
  text-align: right;
  text-transform: uppercase;
  /* -webkit-transition: color 0.3s ease; */
  /* transition: color 0.3s ease; */
  /*   -webkit-transition: 0.2s opacity 0.2s ease-out;
    transition: 0.2s opacity 0.2s ease-out; */
}

.mobileMenu li a:hover,
.mobileMenu li a:active {
  color: #cbcfd3;
  /* -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; */
}

.overlay-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.mobileMenu {
  background-color: #000000;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: transform 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  /*	opacity: 0;
	visibility: hidden;*/
}

.mobileMenu .open {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: transform 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  /*	opacity: 1;
	visibility: visible;*/
}

.mobileMenu .close {
  position: fixed;
  top: 0px;
  height: 60px;
  width: 60px;
  right: 0;
  color: #ffffff;
}

.mobileMenu .close a {
  top: 50%;
  color: #ffffff;
}

.mobileMenu h1.brand {
  color: white;
}

.mobileMenu ul.menu-list li.active a {
  color: white;
}

.mobileMenu ul.menu-list li a {
  color: #a3a3a3;
}

.mobileMenu ul.menu-list li a {
  display: inline-block;
  opacity: 1;
}

.mobileMenu ul.menu-list li a.animate {
  -webkit-transform: scale(1) translateX(0px) translateY(0px);
  -moz-transform: scale(1) translateX(0px) translateY(0px);
  -o-transform: scale(1) translateX(0px) translateY(0px);
  transform: scale(1) translateX(0px) translateY(0px);
  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.6, 0.2, 0.1, 1);
}

.mobileMenu ul.links li a {
  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.6, 0.2, 0.1, 1);
  transition-delay: 0s;
}

.mobileMenu ul.links li a.animate {
  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.6, 0.2, 0.1, 1);
}

.mobileMenu ul.menu-list li a:hover {
  color: white;
}

.mobileMenu ul.menu-list li a:hover,
.mobileMenu ul.links li a:hover {
  transition-property: opacity, transform, color;
  transition-delay: 0s !important;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.6, 0.2, 0.1, 1);

  opacity: 1;
}

.mobileMenu .links li a {
  transition-property: opacity, transform, color;
  transition-duration: 0.3s;
  transition-delay: 0;
  transition-timing-function: cubic-bezier(0.6, 0.2, 0.1, 1);
}

.mobileMenu ul.menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 10vh;
  padding-top: 10vh;
  height: 70vh;
}

.mobileMenu ul.menu-list li {
  font-size: 25px;
  margin: 0;
  padding: 0;
  padding-bottom: 4vh;
}

.mobileMenu .question {
  color: white;
}

.close {
  opacity: 1;
  transition-delay: 0.5s;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  color: #ffffff;
}

.close.animate {
  opacity: 1;
  transition-delay: 0.5s;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  color: #ffffff;
}

/*======================================================
                   Media Queries
  ======================================================*/

/* MOBILE */

@media only screen and (max-width: 576px) and (orientation: portrait) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 1024px) {
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  #navbar ul {
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  #navbar ul {
    display: none;
  }
  .nav-wrapper {
    width: 90%;
  }

  /* Mobile Menu Icon */

  .menuIcon {
    cursor: pointer;
    display: block;
    position: fixed;
    right: 2%;
    top: 0;
    height: 60px;
    width: 60px;
    z-index: 12;
  }

  /* Icon Bars */
  .icon-bars {
    background: black;
    position: absolute;
    top: 45%;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    right: 5%;
  }

  .icon-bars::before {
    background: black;
    content: "";
    position: absolute;
    right: 5%;
    top: -8px;
    height: 2px;
    width: 20px;
    /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  .icon-bars::after {
    margin-top: 0px;
    background: black;
    content: "";
    position: absolute;
    right: 5%;
    bottom: -8px;
    height: 2px;
    width: 20px;
    /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  /* Bars Shadows */
  .icon-bars.overlay {
    background: #999999;
    background: #cbcfd3;
    width: 20px;
    animation: middleBar 3s infinite 0.5s;
    -webkit-animation: middleBar 3s infinite 0.5s;
  }
  @keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::before {
    background: #999999;
    background: #cbcfd3;
    width: 10px;
    animation: topBar 3s infinite 0.2s;
    -webkit-animation: topBar 3s infinite 0s;
  }
  @keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::after {
    background: #999999;
    background: #cbcfd3;
    width: 15px;
    animation: bottomBar 3s infinite 1s;
    -webkit-animation: bottomBar 3s infinite 1s;
  }
  @keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }

  /* Toggle Menu Icon */
  .menuIcon.toggle .icon-bars {
    top: 5px;
    transform: translate3d(0, 5px, 0) rotate(135deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars::before {
    top: 0;
    transition-delay: 0.1s;
    opacity: 0;
  }

  .menuIcon.toggle .icon-bars::after {
    top: 10px;
    transform: translate3d(0, -10px, 0) rotate(-270deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars.overlay {
    width: 20px;
    opacity: 0;
    -webkit-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  #navbar ul {
    display: none;
  }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  #navbar ul {
    display: none;
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  #navbar ul {
    display: none;
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  #navbar ul {
    display: none;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 834px) and (max-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .nav-wrapper {
    width: 90%;
  }

  /* Mobile Menu Icon */

  .menuIcon {
    cursor: pointer;
    display: block;
    position: fixed;
    right: 2%;
    top: 0;
    height: 60px;
    width: 60px;
    z-index: 12;
  }

  /* Icon Bars */
  .icon-bars {
    background: black;
    position: absolute;
    top: 45%;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    right: 5%;
  }

  .icon-bars::before {
    background: black;
    content: "";
    position: absolute;
    right: 5%;
    top: -8px;
    height: 2px;
    width: 20px;
    /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  .icon-bars::after {
    margin-top: 0px;
    background: black;
    content: "";
    position: absolute;
    right: 5%;
    bottom: -8px;
    height: 2px;
    width: 20px;
    /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  /* Bars Shadows */
  .icon-bars.overlay {
    background: #999999;
    background: #cbcfd3;
    width: 20px;
    animation: middleBar 3s infinite 0.5s;
    -webkit-animation: middleBar 3s infinite 0.5s;
  }
  @keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::before {
    background: #999999;
    background: #cbcfd3;
    width: 10px;
    animation: topBar 3s infinite 0.2s;
    -webkit-animation: topBar 3s infinite 0s;
  }
  @keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::after {
    background: #999999;
    background: #cbcfd3;
    width: 15px;
    animation: bottomBar 3s infinite 1s;
    -webkit-animation: bottomBar 3s infinite 1s;
  }
  @keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }

  /* Toggle Menu Icon */
  .menuIcon.toggle .icon-bars {
    top: 5px;
    transform: translate3d(0, 5px, 0) rotate(135deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars::before {
    top: 0;
    transition-delay: 0.1s;
    opacity: 0;
  }

  .menuIcon.toggle .icon-bars::after {
    top: 10px;
    transform: translate3d(0, -10px, 0) rotate(-270deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars.overlay {
    width: 20px;
    opacity: 0;
    -webkit-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .nav-wrapper {
    width: 90%;
  }

  /* Mobile Menu Icon */
  .menuIcon {
    cursor: pointer;
    display: block;
    position: fixed;
    right: 2%;
    top: 0;
    height: 60px;
    width: 60px;
    z-index: 12;
  }

  /* Icon Bars */
  .icon-bars {
    background: black;
    position: absolute;
    top: 45%;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    right: 5%;
  }

  .icon-bars::before {
    background: black;
    content: "";
    position: absolute;
    right: 5%;
    top: -8px;
    height: 2px;
    width: 20px;
    /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  .icon-bars::after {
    margin-top: 0px;
    background: black;
    content: "";
    position: absolute;
    right: 5%;
    bottom: -8px;
    height: 2px;
    width: 20px;
    /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  /* Bars Shadows */
  .icon-bars.overlay {
    background: #999999;
    background: #cbcfd3;
    width: 20px;
    animation: middleBar 3s infinite 0.5s;
    -webkit-animation: middleBar 3s infinite 0.5s;
  }
  @keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::before {
    background: #999999;
    background: #cbcfd3;
    width: 10px;
    animation: topBar 3s infinite 0.2s;
    -webkit-animation: topBar 3s infinite 0s;
  }
  @keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::after {
    background: #999999;
    background: #cbcfd3;
    width: 15px;
    animation: bottomBar 3s infinite 1s;
    -webkit-animation: bottomBar 3s infinite 1s;
  }
  @keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }

  /* Toggle Menu Icon */
  .menuIcon.toggle .icon-bars {
    top: 5px;
    transform: translate3d(0, 5px, 0) rotate(135deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars::before {
    top: 0;
    transition-delay: 0.1s;
    opacity: 0;
  }

  .menuIcon.toggle .icon-bars::after {
    top: 10px;
    transform: translate3d(0, -10px, 0) rotate(-270deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars.overlay {
    width: 20px;
    opacity: 0;
    -webkit-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
}

@media screen and (min-width: 1600px) {
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
}

/* FINE MOBILE */
