/*======================================================
                   Developer Section                
  ======================================================*/

  #developers {
    margin-top: 100px;
  }
  
  .developer-api {
    max-width: 1400px;
    display: grid;
    padding: 0 24px;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 32px;
    position: relative;
    margin: auto;
  }
  
  @media screen and (min-width:801px){
    .developer-api{
      margin-top:20vh;
      margin-bottom: 20vh;
    }
  }
  
  @media screen and (max-width: 800px) {
    .developer-api {
      grid-column-gap: 8px;
    }
  }
  .developer-api:before {
    content: "";
    position: absolute;
    top: 23px;
    right: 24px;
    bottom: 0;
    left: 24px;
    background: #f5f6f7;
    z-index: -1;
    background: #f5f6f7;
  }
  
  @media screen and (max-device-width: 800px) {
    .developer-api {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 78px 54px 62px;
      margin-top: 0;
      margin-bottom: 62px;
    }
  }
  .api-img {
    grid-column: 2/6;
    width: 100%;
    padding-top: 15px;
  }
  
  @media screen and (max-device-width: 800px) {
    .api-img {
      width: 120px;
    }
  }
  .developer-api-content {
    grid-column: 7/16;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 3em;
  }
  
  @media screen and (max-device-width: 800px) {
    .developer-api-content {
      padding-top: 32px;
      align-items: center;
      padding-right: 0;
    }
  }
  .api-title {
    font-size: 36px;
    letter-spacing: -0.04px;
    line-height: 42px;
    font-weight: 700;
  }
  
  @media screen and (max-device-width: 800px) {
    .api-title {
      font-size: 20px;
      letter-spacing: -0.01px;
      line-height: 28px;
    }
  }
  .api-text {
    font-size: 18px;
    letter-spacing: -0.01px;
    line-height: 24px;
    margin: 16px 0 32px;
  }
  
  @media only screen and (max-device-width: 800px) {
    .api-text {
      font-size: 16px;
      letter-spacing: -0.01px;
      line-height: 22px;
      color: #88919e;
      margin-top: 12px;
      padding-right: 0;
    }
  }
  