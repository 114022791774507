/*======================================================
                   About Section                 
  ======================================================*/

#about {
  height: 100vh;
}

.question {
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .question {
    padding-bottom: 20px;
  }

  .answer {
    padding-bottom: 20px;
  }
}

.qa {
  margin-left: 0px;
  margin-right: 0px;
}

.answer {
  font-size: 18px;
  line-height: 30px;
  color: #a2a5a8;
  box-sizing: border-box;
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 800px) {
  .blurb-a {
    padding-right: 15px;
  }

  .blurb-b {
    padding-left: 15px;
  }
}

/* Portrait and Landscape */
@media only screen and (max-device-width: 767px) {
  .blurb-a {
    padding-right: 0px;
  }

  .blurb-b {
    padding-left: 0px;
  }
}

.answer span {
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .answer {
    font-size: 16px;
    line-height: 28px;
  }
}

.comparison-blurb {
  color: #a2a5a8;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.about {
  margin: auto;
}

@media only screen and (min-width: 1024px) {
  .about {
    width: 90%;
  }
}

@media only screen and (max-width: 1024px) {
  .about {
    width: 90%;
  }
}

.about a.link {
  margin-top: 30px;
  display: block;
  float: right;
  transition: 0.3s;
}

.about-wrapper {
  max-width: 1400px;
  margin: auto;
}

.about-items-outer {
  margin-bottom: 120px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 680px) {
  .about-items-outer {
    padding-bottom: 80px;
    margin-bottom: 70px;
  }
}
.about-items {
  max-width: 1280px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 63px;
}

@media only screen and (max-width: 680px) {
  .about-items {
    display: block;
  }
}

@media only screen and (max-width: 680px) {
  .aboutItem {
    display: grid;
    grid-auto-flow: column;
    padding-bottom: 47px;
  }
}
.about-item-img {
  width: 56px;
  height: 56px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left;
  object-position: left;
  margin-bottom: 27px;
}

@media only screen and (max-width: 680px) {
  .about-item-img {
    width: 32px;
    -o-object-position: left bottom;
    object-position: left bottom;
  }
}

@media only screen and (max-width: 680px) {
  .about-item-content {
    padding-top: 17px;
    padding-left: 24px;
  }
}
.about-item-title {
  font-size: 20px;
  letter-spacing: -0.01px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 7px;
}

@media only screen and (max-width: 680px) {
  .about-item-title {
    font-size: 16px;
    letter-spacing: -0.01px;
    line-height: 22px;
    margin-bottom: 4px;
  }
}
.about-item-text {
  font-size: 18px;
  letter-spacing: -0.01px;
  line-height: 30px;
  color: #a2a5a8;
}

@media only screen and (max-width: 767px) {
  .about-item-text {
    font-size: 16px;
    line-height: 28px;
  }
}

.about-borderless {
  visibility: visible;
  -webkit-transform: translateY(0) scale(1);
  opacity: 1;
  transform: translateY(0) scale(1);
  opacity: 1;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
    opacity 0.9s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
  transition: transform 0.9s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
    opacity 0.9s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
}

@media only screen and (max-device-width: 800px) {
  .about-borderless {
    padding-bottom: 15px;
  }
}

.about-tagline {
  text-align: center;
  margin: auto;
}

.about-description {
  visibility: visible;
  -webkit-transform: translateY(0) scale(1);
  opacity: 1;
  transform: translateY(0) scale(1);
  opacity: 1;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
    opacity 0.9s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
  transition: transform 0.9s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
    opacity 0.9s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
  color: #7e8794;
}

@media only screen and (min-width: 801px) {
  .about-description {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}
