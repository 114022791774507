/*======================================================
               Exchange Comparison Section                 
  ======================================================*/

:root {
  --color-secondary-light: hsl(0, 0%, 96%);
  --color-secondary-text: #999999;
  --font-xs: 0.75rem;
  --font-sm: 0.9375rem;
  --font-base: 1rem;
  --font-md: 1.125rem;
  --font-lg: 1.25rem;
  --font-xl: 1.375rem;
  --font-2xl: 1.875rem;
  --font-3xl: 2.25rem;
  --font-4xl: 3rem;
  --font-5xl: 3.75rem;
  --font-6xl: 4.5rem;
  --font-weight-xlight: 150;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semibold: 600;
  --line-height-none: 1;
  --line-height-tight: 1.275;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.65;
  --line-height-loose: 2;
  --letter-spacing-tighter: -0.05em;
  --letter-spacing-tight: -0.025em;
  --letter-spacing-wide: 0.025em;
  --letter-spacing-wider: 0.05em;
  --letter-spacing-widest: 0.1em;
  --spacing-0: 0;
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.5rem;
  --spacing-6: 2rem;
  --spacing-7: 3rem;
  --spacing-8: 4rem;
  --spacing-9: 6rem;
  --spacing-10: 8rem;
  --spacing-11: 12rem;
  --spacing-12: 16rem;
  --spacing-13: 24rem;
  --spacing-14: 32rem;
  --spacing-15: 40rem;
  --spacing-16: 48rem;
  --spacing-17: 56rem;
  --border-radius-small: 2px;
  --border-radius-default: 4px;
  --border-radius-lg: 8px;
  --border-radius-full: 999px;
  --width-lg: 1048px;
  --box-shadow-small: 0 2px 4px hsla(239, 30%, 28%, 0.095);
  --box-shadow-base: 0 5px 7px hsla(239, 30%, 28%, 0.12),
    0 1px 3px hsla(0, 0%, 0%, 0.085);
  --box-shadow-larger: 0 8px 15px hsla(239, 35%, 40%, 0.11),
    0 3px 6px hsla(0, 0%, 0%, 0.085);
  --box-shadow-large: 0 13px 27px -5px hsla(239, 30%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
    0 -6px 16px -6px hsla(0, 0%, 0%, 0.025);
  --box-shadow-huge: 1px 1px 6px 0 hsla(239, 44%, 18%, 0.05),
    38px 63px 124px -26px hsla(239, 30%, 28%, 0.5),
    22px 36px 77px -37px hsla(0, 0%, 0%, 0.61);
  --box-shadow-alt: 0 52px 98px -29px rgba(50, 50, 93, 0.27),
    0 30px 62px -30px rgba(0, 0, 0, 0.31),
    0 -18px 62px -10px rgba(0, 0, 0, 0.027);
}

#comparison {
  margin: auto;
  width: 90%;
}

/* Remove in final version */
@media only screen and (max-device-width: 750px) {
  #comparison {
    margin-top: -150px;
  }
}

@media only screen and (max-width: 1024px) {
  #comparison {
    width: 90%;
    /* margin-top: 80%; */
  }
}

.comparison-wrapper {
  max-width: 1400px;
  margin: auto;
  position: relative;
  overflow: visible;
}

@media only screen and (min-device-width: 1024px) {
  .comparison-wrapper {
    margin-top: 10%;
  }
}

@media only screen and (min-width: 768px) {
  .comparison-wrapper {
    overflow: initial;
  }
}

.comparison-wrapper p.comparison-blurb {
  text-align: center;
  color: #a2a5a8;
}

@media only screen and (min-width: 801px) {
  .comparison-wrapper p.comparison-blurb {
    padding-bottom: 65px;
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .comparison-wrapper p.comparison-blurb {
    padding-bottom: 65px;
    font-size: 18px;
    line-height: 28px;
  }
}

.comparison {
  margin: var(--spacing-12) auto 0;
}

@media screen and (min-width: 400px) {
  .comparison {
    margin: var(--spacing-12) auto 0;
  }
}

@media screen and (min-width: 580px) {
  .comparison {
    margin: var(--spacing-11) auto 0;
  }
}
.comparison h2,
.comparison > p > time {
  font-weight: var(--font-weight-semibold);
}
.comparison h2 {
  font-size: var(--font-2xl);
  margin: 0;
  letter-spacing: var(--letter-spacing-wide);
  margin-bottom: var(--spacing-5);
  color: var(--color-primary-dark);
  text-align: center;
  margin-top: var(--spacing-8);
  line-height: var(--line-height-tight);
}

@media screen and (min-width: 400px) {
  .comparison h2 {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .comparison h2 {
    font-size: var(--font-3xl);
  }
}
.comparison h2 span {
  display: block;
  color: var(--color-black);
  padding-top: var(--spacing-2);
}
.comparison-table,
.comparison > p {
  margin-bottom: var(--spacing-2);
}
.comparison > p {
  text-align: center;
  font-size: var(--font-lg);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-relaxed);
  max-width: 520px;
  margin: 0 auto;
}
.comparison > p > time {
  white-space: nowrap;
}
.comparison-table {
  display: flex;
  justify-content: flex-start;
}

@media screen and (min-width: 768px) {
  .comparison-table {
    margin-left: auto;
    margin-right: auto;
  }
}

.comparison-exchanges {
  flex: none;
  border-radius: var(--border-radius-lg);
  box-shadow: 0 3px 3px 0 rgba(24, 56, 89, 0.029),
    0 0 5px 1px rgba(0, 0, 0, 0.047);
  align-self: flex-end;
  margin-bottom: var(--spacing-2);
  font-size: var(--font-sm);
  font-weight: var(--font-weight-semibold);
  position: relative;
}

@media screen and (min-width: 768px) {
  .comparison-exchanges {
    font-size: var(--font-base);
  }
}
.comparison-exchanges > div {
  display: flex;
  justify-content: center;
  height: var(--spacing-7);
  flex-direction: column;
  background: var(--color-primary-text);
  padding: 0 var(--spacing-2);
  position: relative;
}

@media screen and (min-width: 400px) {
  .comparison-exchanges > div {
    padding: 0 var(--spacing-4);
  }
}

@media screen and (min-width: 768px) {
  .comparison-exchanges > div {
    padding: 0 var(--spacing-5);
  }
}
.comparison-exchanges > div small {
  font-size: var(--font-xs);
  color: var(--color-secondary-text);
}
.comparison-exchanges .comparison-description {
  pointer-events: none;
  opacity: 0;
  transition: opacity 317ms ease;
  position: absolute;
  left: 100%;
  top: calc(-1 * var(--spacing-5));
  background: var(--color-secondary-light);
  box-shadow: var(--box-shadow-alt);
  padding: var(--spacing-5) var(--spacing-5) var(--spacing-4);
  font-size: var(--font-sm);
  font-weight: var(--font-weight-xlight);
  z-index: 10;
  border-radius: var(--border-radius-default);
  width: 500px;
  max-width: 66vw;
}

.comparison-exchanges .comparison-description:hover,
.comparison-exchanges > div:hover span,
.comparison-exchanges .comparison-description:focus,
.comparison-exchanges > div:focus span,
.comparison-exchanges .comparison-description:active,
.comparison-exchanges > div:active span {
  opacity: 1;
  pointer-events: initial;
}

@media only screen and (pointer: fine) {
  .comparison-exchanges .comparison-description:hover,
  .comparison-exchanges > div:hover span,
  .comparison-exchanges .comparison-description:focus,
  .comparison-exchanges > div:focus span,
  .comparison-exchanges .comparison-description:active,
  .comparison-exchanges > div:active span {
    opacity: 1;
    pointer-events: initial;
  }
}
.order-summary-list li:nth-child(odd),
.comparison-exchanges > div:nth-child(even),
.comparison-item:nth-child(odd) {
  background: var(--color-secondary-light);
}

@media screen and (min-width: 768px) {
  .comparison-exchanges > div:first-child {
    border-top-left-radius: var(--border-radius-lg);
  }
  .comparison-exchanges > div:last-child {
    border-bottom-left-radius: var(--border-radius-lg);
  }
}
.comparison-details {
  flex: 1;
  box-shadow: 0 4px 32px 0 rgba(24, 56, 89, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.067);
  border-radius: var(--border-radius-lg);
  min-width: 0;
  position: relative;
}

.comparison-details-header {
  display: flex;
  font-weight: var(--font-weight-semibold);
  background: var(--color-primary-text);
  border-bottom: 2px solid var(--color-secondary-light);
  border-top-left-radius: var(--border-radius-lg);
  justify-content: flex-end;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .comparison-details-header {
    border-top-right-radius: var(--border-radius-lg);
  }
}
.comparison-details-header > div:first-child {
  width: 100%;
  flex: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--color-secondary-text);
  line-height: 1;
  padding-top: var(--spacing-5);
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  font-size: var(--font-md);
}

@media screen and (min-width: 400px) {
  .comparison-details-header > div:first-child {
    font-size: var(--font-xl);
  }
}
.comparison-details-header > div {
  display: flex;
  flex-direction: column;
  color: var(--color-primary-dark);
  flex: 1;
  padding: 0 var(--spacing-1) var(--spacing-4) var(--spacing-2);
  justify-content: baseline;
  font-size: var(--font-sm);
  min-width: 0;
}

@media screen and (min-width: 400px) {
  .comparison-details-header > div {
    font-size: var(--font-base);
    padding: 0 var(--spacing-3) var(--spacing-4) var(--spacing-4);
  }
}

@media screen and (min-width: 768px) {
  .comparison-details-header > div {
    padding: 0 var(--spacing-1) var(--spacing-5) var(--spacing-5);
    font-size: var(--font-md);
  }
}
.comparison-details-header > div:nth-child(2) {
  border-right: 2px solid var(--color-secondary-light);
}
.comparison-details-header > div small {
  color: var(--color-secondary-text);
  font-size: var(--font-xs);
  font-weight: var(--font-weight-normal);
  padding-top: var(--spacing-2);
}

@media screen and (min-width: 450px) {
  .comparison-details-header > div small {
    font-size: calc(var(--font-sm) - 1px);
  }
}
.comparison-item {
  display: flex;
  height: var(--spacing-7);
  background: var(--color-primary-text);
  color: var(--color-secondary-text);
}

@media only screen and (min-width: 801px) {
  p.comparison-blurb {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 800px) {
  p.comparison-blurb {
    font-size: 18px;
    line-height: 28px;
  }
}

.comparison-item > div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: var(--spacing-4);
  padding: 10 10 10 10px;
}

#multichaincomparison .comparison-item > div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: var(--spacing-4);
  padding: 10 10 10 10px;
}

@media screen and (min-width: 768px) {
  .comparison-item > div {
    padding-right: var(--spacing-5);
  }
}
.comparison-item > div:first-child {
  border-right: 2px solid var(--color-secondary-light);
}
.comparison-item:last-child {
  border-bottom-left-radius: var(--border-radius-lg);
  height: calc(var(--spacing-7) + var(--spacing-2));
}

#multichaincomparison .comparison-item > div:first-child {
  color: #1b7940 !important;
  font-weight: var(--font-weight-semibold);
}

@media only screen and (min-width: 768px) {
  #multichaincomparison .comparison-item > div {
    padding: var(--spacing-5) var(--spacing-5);
  }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
  #multichaincomparison .comparison-item > div {
    padding: var(--spacing-4) var(--spacing-4);
  }
}

@media screen and (min-width: 768px) {
  .comparison-item:last-child {
    border-bottom-right-radius: var(--border-radius-lg);
  }
}

.comparison-information {
  text-align: right;
}

.comparison_information {
  text-align: right;
}

.multichainWrapper p.comparison-blurb {
  text-align: center;
  color: #a2a5a8;
}

#multichaincomparison div.multichainWrapper div.comparison-table {
  padding-top: 50px;
}

/*======================================================
                   Media Queries
  ======================================================*/

/* MOBILE */

@media only screen and (max-width: 576px) and (orientation: portrait) {
}

@media only screen and (max-width: 1024px) {
  #multichaincomparison .comparison-details {
    font-size: 0.8em;
  }

  #multichaincomparison .comparison-exchanges > div {
    height: var(--spacing-8);
  }

  #multichaincomparison .comparison-item {
    height: var(--spacing-8);
  }

  #multichaincomparison .comparison-exchanges {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .comparison-wrapper {
    margin-top: 200px;
  }

  .comparison-exchanges .comparison-description {
    width: 110px;
    font-size: var(--font-xs);
  }
}

/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .comparison-wrapper {
    margin-top: 250px;
  }

  .comparison-exchanges .comparison-description {
    width: 130px;
  }
}

/* Portrait */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .comparison-wrapper {
    margin-top: 275px;
  }

  .comparison-exchanges .comparison-description {
    width: 150px;
    font-size: var(--font-base);
  }
}

/* Portrait */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .comparison-wrapper {
    margin-top: 275px;
  }

  .comparison-exchanges .comparison-description {
    width: 130px;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .comparison-exchanges .comparison-description {
    width: 400px;
    font-size: var(--font-sm);
  }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  #comparison {
    /* margin-top:65%; */
  }

  .comparison-exchanges .comparison-description {
    width: 400px;
    font-size: var(--font-sm);
  }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 834px) and (max-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .comparison-exchanges .comparison-description {
    width: 400px;
    font-size: var(--font-sm);
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .comparison-exchanges .comparison-description {
    width: 500px;
    font-size: var(--font-sm);
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
}

@media screen and (min-width: 1600px) {
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
}

/* FINE MOBILE */
