/*======================================================
                   Multichain Comparison                
  ======================================================*/

.section {
  padding-top: 48px;
  padding-bottom: 48px;
}
@media only screen and (min-width: 576px) {
  .section {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
@media only screen and (min-width: 992px) {
  .section {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

#multichaincomparison {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1380px) {
  #multichaincomparison {
    margin-top: 150px;
  }
}

@media only screen and (max-width: 1024px) {
  #multichaincomparison {
    width: 90%;
  }
}

.multichainWrapper {
  max-width: 1400px;
  margin: auto;
}

.bg-gs-grey {
  background-size: contain;
  color: #000000;
}
/* @media only screen and (max-width: 575px) {
    .bg-gs-grey {
      background: #f2f5f7
        url(/lienzo-assets/img/background/grey-mobile-57211512bff3f6c8e73623b9511013b0.png)
        no-repeat 0 0 !important;
    }
  }
  .jumbotron-image.bg-gs-grey {
    background: #f2f5f7
      url(/lienzo-assets/img/background/grey-453d5a95f7a61e92034127046c65872c.png)
      no-repeat 0 0 !important;
  }
  @media only screen and (max-width: 575px) {
    .jumbotron-image.bg-gs-grey {
      background: #f2f5f7
        url(/lienzo-assets/img/background/white-mobile-d0fd27564d7f4ba446b4edd061539f3b.png)
        no-repeat 0 0 !important;
    }
  }
  */

.text-primary {
  color: #333333 !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #253655 !important;
}
.bg-primary .text-primary {
  color: #fff !important;
}

.text-success {
  color: #1b7940 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #1b7940 !important;
}
.text-success .h1,
.text-success .h2,
.text-success .h3,
.text-success h1,
.text-success h2,
.text-success h3,
.text-success.h1,
.text-success.h2,
.text-success.h3,
h1.text-success,
h2.text-success,
h3.text-success {
  color: #2ed06e !important;
}
.bg-primary .text-success {
  color: #2ed06e !important;
}
.text-info {
  color: #00a4df !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #00a4df !important;
}
.text-info .h1,
.text-info .h2,
.text-info .h3,
.text-info h1,
.text-info h2,
.text-info h3,
.text-info.h1,
.text-info.h2,
.text-info.h3,
h1.text-info,
h2.text-info,
h3.text-info {
  color: #00b9ff !important;
}
.bg-primary .text-info {
  color: #00b9ff !important;
}
.text-warning {
  color: #9e5f00 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #9e5f00 !important;
}
.text-warning .h1,
.text-warning .h2,
.text-warning .h3,
.text-warning h1,
.text-warning h2,
.text-warning h3,
.text-warning.h1,
.text-warning.h2,
.text-warning.h3,
h1.text-warning,
h2.text-warning,
h3.text-warning {
  color: #ffa600 !important;
}
.bg-primary .text-warning {
  color: #ffa600 !important;
}
.text-danger {
  color: #c22e2e !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #c22e2e !important;
}
.text-danger .h1,
.text-danger .h2,
.text-danger .h3,
.text-danger h1,
.text-danger h2,
.text-danger h3,
.text-danger.h1,
.text-danger.h2,
.text-danger.h3,
h1.text-danger,
h2.text-danger,
h3.text-danger {
  color: #f53636 !important;
}
.bg-primary .text-danger {
  color: #f53636 !important;
}
.text-inverse {
  color: #fff !important;
}
a.text-inverse:focus,
a.text-inverse:hover {
  color: #f2f5f7 !important;
}

.bg-gs-grey .jumbotron h1,
.bg-gs-white .jumbotron h1 {
  color: #000000;
}

.price-table .table th {
  padding: 12px 24px;
  vertical-align: middle;
}
.price-table .table th:first-child {
  border-top-left-radius: 3px;
}
.price-table .table th:last-child {
  border-top-right-radius: 3px;
}
.price-table .table td {
  padding: 12px 24px;
  color: #6f8691;
  vertical-align: middle;
}
.price-table .table .primary th {
  border-top: 0;
  background: #666666;
}

.price-table .table .primary_MCap th {
  border-top: 0;
  background: #cbcfd3;
}

.price-table .table img {
  min-width: 80px;
  max-width: 100px;
}
.vertical-pricing .table td:nth-child(2n),
.vertical-pricing .table th:nth-child(2n) {
  text-align: right;
}
@media only screen and (min-width: 768px) {
  .price-comparison .table th {
    padding: 24px 12px;
  }
  .price-comparison .table th:first-child {
    padding-left: 24px;
  }
  .price-comparison .table th:last-child {
    padding-right: 24px;
  }
  .price-comparison .table th:first-child {
    min-width: 200px;
  }
}
@media only screen and (max-width: 768px) {
  .price-comparison .table .primary th {
    background: #333333;
  }
  .price-comparison .table td {
    border: 0;
    text-align: right;
  }
  .price-comparison .table td.primary {
    background: #cbcfd3;
    text-align: left;
  }
  .price-comparison .logo {
    background-position: 0;
  }
}
.featured-header-cell {
  position: relative;
  min-width: 120px;
  background: #333333 !important;
}
.featured-header-cell:before {
  content: "";
  position: absolute;
  top: -16px;
  left: 0;
  width: 100%;
  height: 16px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #333333 !important;
}
.featured-cell {
  position: relative;
  border: 0 !important;
  background: #fff;
}
.featured-cell:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 70%;
  height: 2px;
  background: #f2f5f7;
}
.featured-cell:before {
  content: "";
  position: absolute;
  top: 0;
  left: -34%;
  width: 168%;
  height: 100%;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0) 0,
    rgba(34, 48, 72, 0.05) 20%,
    hsla(0, 0%, 100%, 0) 21%,
    hsla(0, 0%, 100%, 0) 80%,
    rgba(34, 48, 72, 0.05) 0,
    hsla(0, 0%, 100%, 0)
  );
}
tr:last-of-type .featured-cell:after {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 0;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 3px 55px 16px rgba(34, 48, 73, 0.05);
  -webkit-clip-path: polygon(-40% 50%, 140% 50%, 140% 250%, -40% 250%);
  clip-path: polygon(-40% 50%, 140% 50%, 140% 250%, -40% 250%);
}

.container {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
}
.container-fluid {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
.container {
  padding-right: 16px;
  padding-left: 16px;
}
@media only screen and (min-width: 576px) {
  .container {
    padding-right: 24px;
    padding-left: 24px;
  }
}
@media only screen and (min-width: 992px) {
  .container {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media only screen and (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}

/*======================================================
                   Media Queries
  ======================================================*/

/* MOBILE */

@media only screen and (max-width: 576px) and (orientation: portrait) {
}

@media only screen and (max-width: 1024px) {
  #multichaincomparison {
    margin-top: 700px;
  }
}

@media only screen and (max-width: 800px) {
  #multichaincomparison {
    margin-top: 500px;
  }
}

@media only screen and (max-width: 600px) {
  #multichaincomparison {
    margin-top: 350px;
  }
}

@media only screen and (max-width: 450px) {
  #multichaincomparison {
    margin-top: 250px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  #multichaincomparison {
    margin-top: 150px;
  }

  .comparison-exchanges .comparison-description {
    width: 110px;
    font-size: var(--font-xs);
  }
}

/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .comparison-exchanges .comparison-description {
    width: 130px;
  }

  #multichaincomparison {
    margin-top: 200px;
  }
}

/* Portrait */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .comparison-wrapper {
    margin-top: 300px;
  }

  .comparison-exchanges .comparison-description {
    width: 150px;
    font-size: var(--font-base);
  }
}

/* Portrait */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .comparison-wrapper {
    margin-top: 275px;
  }

  .comparison-exchanges .comparison-description {
    width: 130px;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .comparison-exchanges .comparison-description {
    width: 400px;
    font-size: var(--font-sm);
  }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .comparison-exchanges .comparison-description {
    width: 325px;
    font-size: var(--font-sm);
  }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  #multichaincomparison {
    margin-top: 325px;
  }
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  #multichaincomparison {
    margin-top: 750px;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 834px) and (max-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .comparison-exchanges .comparison-description {
    width: 400px;
    font-size: var(--font-base);
  }
  #multichaincomparison {
    margin-top: 325px;
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  #multichaincomparison {
    margin-top: 325px;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  #multichaincomparison {
    margin-top: 0px;
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .comparison-exchanges .comparison-description {
    width: 500px;
    font-size: var(--font-base);
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  #multichaincomparison {
    margin-top: 250px;
  }

  .featureTitle {
    margin-top: 50px;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  #multichaincomparison {
    margin-top: 0px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
}

@media screen and (min-width: 1600px) {
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
}

/* FINE MOBILE */
