/*======================================================
                   Footer Section               
  ======================================================*/

.footer {
  background: #000000;
  color: #fff;
  padding: 88px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

@media only screen and (max-device-width: 640px) {
  .footer {
    padding: 74px 0;
  }
}
.footer-grid {
  width: 90%;
  display: grid;
  margin: 0 auto;
  padding: 0 24px;
  padding-top: 100px;
  padding-bottom: 15px;
  flex: 1 1;
  grid-template-columns: -webkit-min-content minmax(20px, 156px) repeat(
      4,
      -webkit-min-content minmax(20px, 120px)
    );
  grid-template-columns: min-content minmax(20px, 156px) repeat(
      4,
      min-content minmax(20px, 120px)
    );
  grid-row-gap: 56px;
  background-color: #000000;
  color: #ffffff;
  max-width: 1400px;
}

@media only screen and (max-device-width: 640px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 48px;
  }
}

@media only screen and (max-device-width: 640px) {
  .footer-logo {
    grid-column: 1/3;
    max-width: 175px;
    height: auto;
  }
  .footer-gap {
    display: none;
  }
}
.footer-title {
  font-weight: 700;
  margin-bottom: 18px;
}

@media only screen and (max-device-width: 640px) {
  .footer-title {
    margin-bottom: 20px;
  }
}
.footer-link {
  text-decoration: none;
  color: inherit;
  display: block;
  margin: 12px 0;
}

@media only screen and (max-device-width: 640px) {
  .footer-link {
    margin: 16px 0;
  }
}
.footer-link:last-child {
  margin-bottom: 0;
}
.footer-link:focus,
.footer-link:hover {
  opacity: 0.7;
  outline: none;
}
.footer-copyright {
  font-size: 14px;
  letter-spacing: -0.01px;
  line-height: 18px;
  color: #999999;
  grid-column: 3/-1;
  grid-row: 2;
}

@media only screen and (max-device-width: 640px) {
  .footer-copyright {
    grid-column: auto;
    grid-row: auto;
  }
}

.pre-footer {
  background: #000000;
  color: #fff;
  padding: 90px 24px 40px;
  justify-content: center;
  border-bottom: 1px solid #333333;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer {
    padding: 40px 24px;
  }
}
.pre-footer-grid {
  display: flex;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-grid {
    display: block;
    text-align: center;
    width: 90%;
  }
}

.pre-footer-tagline {
  font-size: 36px;
  letter-spacing: -0.04px;
  line-height: 42px;
  font-weight: 700;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-tagline {
    font-size: 32px;
    letter-spacing: -0.36px;
    line-height: 38px;
  }
}
.pre-footer-subheading {
  font-size: 20px;
  letter-spacing: -0.01px;
  line-height: 28px;
  margin: 24px 0 48px;
  width: 70%;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-subheading {
    font-size: 16px;
    letter-spacing: -0.01px;
    line-height: 22px;
    width: 90%;
    margin: 10px auto;
  }
}
.pre-footer-buttons {
  text-align: right;
  padding-top: 2em;
  width: 70%;
  margin: auto;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-buttons {
    text-align: center;
    padding-top: 0.5em;
  }
}
.pre-footer-buttons a {
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-buttons a {
    margin-left: 0.5rem;
  }
}

.main-footer {
  background-color: #000000;
}
