/*======================================================
                  Features Section                 
  ======================================================*/

#keyFeatures {
  height: 100vh;
}

/* 250px margin for pre-release version only */
.featuresWrapper {
  margin-top: 250px;
}

.features-container {
  width: 100vw;
  overflow: hidden;
}
.features {
  max-width: 1280px;
  display: grid;
  margin: auto;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 32px;
  padding: 64px 24px;
}

@media only screen and (max-width: 800px) {
  .features {
    grid-column-gap: 8px;
    display: block;
    padding-bottom: 0;
  }
}
.features-selected {
  font-size: 20px;
  letter-spacing: -0.01px;
  line-height: 28px;
  font-weight: 700;
}

@media only screen and (max-width: 800px) {
  .features-selected {
    display: none;
  }
}
.features-title {
  font-size: 36px;
  letter-spacing: -0.04px;
  line-height: 42px;
  font-weight: 700;
  padding-top: 16px;
  flex: 0.4 1;
  margin-bottom: 8px;
}

@media only screen and (max-width: 800px) {
  .features-title {
    font-size: 24px;
    letter-spacing: -0.03px;
    line-height: 32px;
    padding-top: 20px;
  }
}
.features-info {
  grid-column: 1/5;
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  padding-bottom: 52px;
  margin: auto;
  width: 90%;
}

@media only screen and (max-width: 800px) {
  .features-info {
    padding-top: 26px;
  }
}
.features-buttons {
  display: flex;
  flex-direction: column;
  margin: 0 -24px;
  padding: 0 24px;
  max-width: 100vw;
  box-sizing: border-box;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  flex: 0.45 1;
}
.features-buttons::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 800px) {
  .features-buttons {
    order: -1;
    flex-direction: row;
  }
}
.features-button {
  background: none;
  border: 0;
  margin: 0;
  cursor: pointer;
  color: inherit;
  font-size: 16px;
  letter-spacing: -0.01px;
  line-height: 22px;
  text-align: left;
  height: 34px;
  border-left: 1px solid #e9ebef;
  padding: 0 16px;
  white-space: nowrap;
  flex-shrink: 0;
  -webkit-transition: color 0.15s ease-in-out, border 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, border 0.15s ease-in-out;
}

@media only screen and (max-width: 800px) {
  .features-button {
    border-left: 0;
    padding: 0;
    margin-right: 24px;
    color: #c7cdd6;
  }
  .features-button:last-child {
    padding-right: 24px;
  }
}

@media only screen and (min-width: 800px) {
  .features-button:hover {
    color: #4896f0;
  }
}
.features-button:focus {
  outline: 0;
}
.features-button-selected {
  outline: none;
  color: #4896f0;
  border-left-color: currentColor;
}

@media only screen and (max-width: 800px) {
  .features-button-selected {
    font-weight: 400;
  }
}
.features-slides {
  margin-top: 4px;
  grid-column: 6/13;
  position: relative;
  width: calc(50vw + 96px);
  display: flex;
}

@media only screen and (max-width: 800px) {
  .features-slides {
    margin: 0 -24px;
    width: 100vw;
    right: 0;
  }
}
.features-images {
  height: 100vh;
  max-height: 80vw;
  width: 100%;
  overflow: hidden;
  position: relative;
  top: 3em;
}

@media only screen and (max-width: 800px) {
  .features-images {
    height: auto;
    padding-bottom: 111%;
    top: 0;
  }
}
.features-images-wrapper,
.features-img-container {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  letter-spacing: -1.1em;
  vertical-align: top;
}
.features-img-container {
  background: #fff;
  overflow: hidden;
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.features-img-container-selected {
  opacity: 1;
}
.features-img {
  display: inline-block;
  height: 100%;
}

@media only screen and (max-width: 800px) {
  .features-img {
    height: auto;
    width: 100%;
  }
}

/*======================================================
                  Slider General               
  ======================================================*/

@font-face {
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: 400;
  src: url(../components/assets/fonts/FoundersGrotesk-Regular.otf)
    format("opentype");
}

$colorMain: #ffffff;
$colorSecondary: #c7cdd6;
$colorLight: #333333;
$colorDark: #000000;

$breakpointM: 700px;

/*======================================================
                   Feature Slider 
  ======================================================*/

.featureSectionContainer {
  width: 100vw;
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    padding-bottom: 80px;
    margin-bottom: 70px;
  }
}

.featureSlider {
  display: grid;
  margin: auto;
  grid-template-columns: repeat(12, 2fr);
  grid-column-gap: 32px;
  width: 90%;
  max-width: 1400px;
}

@media only screen and (max-width: 1024px) {
  .featureSlider {
    display: block;
    grid-column-gap: 8px;
    padding-bottom: 0;
    width: 90%;
  }
}

.featureTitle {
  font-size: 36px;
  letter-spacing: -0.04px;
  line-height: 42px;
  font-weight: 700;
  padding-top: 16px;
  flex: 0.4 1;
  padding-bottom: 8px;
  min-height: 100px;

  @media only screen and (max-width: 1024px) {
    font-size: 24px;
    letter-spacing: -0.03px;
    line-height: 32px;
    padding-top: 20px;
    height: 130px;
    text-align: center;
    margin-bottom: 50px;
  }

  @media only screen and (max-width: 550px) {
    margin-bottom: 75px;
  }
}

.feature-name {
  font-size: 20px;
  letter-spacing: -0.01px;
  line-height: 28px;
  font-weight: 700;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.featureSlider__panel__controls {
  grid-column: 1/6;
  display: flex;
  flex-direction: column;

  @media only screen and (min-device-width: 1025px) {
    .featureSlider__panel__controls {
      padding-bottom: 60px;
      padding-top: 60px;
    }
  }

  @media only screen and (max-device-width: 1024px) {
    .featureSlider {
      padding-top: 26px;
    }
    .featureSlider__panel__controls {
      padding-bottom: 0px;
    }
  }

  &.featureSlider__panel__controls {
    background-color: $colorMain;

    .featureSelect {
      display: flex;
      flex-direction: column;
      margin: 0 -24px;
      padding: 0 24px;
      max-width: 100vw;
      box-sizing: border-box;
      -webkit-overflow-scrolling: touch;
      flex: 0.45 1;

      &::-webkit-scrollbar {
        display: none;
      }

      @media only screen and (max-width: 1024px) {
        order: -1;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .featureSelect__item {
        background: none;
        border: 0;
        margin: 0;
        cursor: pointer;
        color: #c7cdd6;
        font-size: 18px;
        letter-spacing: -0.01px;
        line-height: 22px;
        text-align: left;
        height: 34px;
        border-left: 1px solid #e9ebef;
        padding: 0 16px;
        white-space: nowrap;
        flex-shrink: 0;
        -webkit-transition: color 0.15s ease-in-out, border 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, border 0.15s ease-in-out;
        padding-top: 10px;

        @media only screen and (max-width: 1024px) {
          border-left: 0;
          padding: 0;
          margin-right: 24px;
        }

        /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

        /* Portrait and Landscape */
        @media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
        }

        /* Portrait */
        @media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
          margin-right: 14px;
          font-size: 16px;
        }

        /* Landscape */
        @media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        }

        /* ----------- iPhone 6, 6S, 7 and 8 ----------- */

        /* Portrait and Landscape */
        @media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
        }

        /* Portrait */
        @media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
          margin-right: 20px;
          font-size: 16px;
        }

        &:hover {
          @media only screen and (min-width: 800px) {
            color: #999999;
          }
        }

        &:focus {
          outline: 0;
        }

        &:hover::after {
          background: $colorSecondary;
          opacity: 1;
        }

        &.featureActive {
          outline: none;
          color: #000000;
          border-left-color: currentColor;

          &::after {
            opacity: 1;

            @media only screen and (max-width: 800px) {
              font-weight: 400;
            }
          }
        }
      }
    }

    .featureDescription {
      font-size: 11px;
      color: $colorDark;
      margin: 10px 15% 0 0;
    }
  }

  .featureSlideContainer {
    margin-top: 4px;
    grid-column: 6/13;
    position: relative;
    width: calc(50vw + 96px);
    display: flex;

    @media only screen and (max-width: 1024px) {
      margin: 0 -24px;
      width: 100vw;
      right: 0;
    }
    /*
      .featureSlide {
        height: 100vh;
        max-height: 80vw;
        overflow: hidden;
        position: relative;
        top: 3em;
        transition: all 0.5s ease;
  
        @media only screen and (max-width: 800px) {
          height: auto;
          padding-bottom: 111%;
          top: 0;
        }
  
        &.featureActive {
          left: 0 !important;
          opacity: 1 !important;
          z-index: 1 !important;
          width: 100% !important;
        }
      }
      */
  }
}

.featureSlider_panel {
  position: relative;
  width: 50%;
  height: inherit;

  &.featureSlider_panel_frame {
    margin-top: 4px;
    grid-column: 6/13;
    position: relative;
    width: calc(50vw + 96px);
    display: flex;

    @media only screen and (min-width: 1025px) {
      height: 80vh;
    }

    @media only screen and (max-width: 1024px) {
      margin: auto;
      width: 90%;
      display: inline-block;
      position: absolute;
    }
  }

  .featuresWrapper {
    max-width: 1400px;
  }

  .feature_slide {
    position: absolute;
    height: 100%;
    background-size: contain;
    transition: all 0.5s ease;
    max-height: 80vw;
    width: 95%;
    overflow: hidden;
    background-repeat: no-repeat;
    max-width: 1024px;

    @media only screen and (min-width: 1500px) {
      background-position: center;
      background-size: contain;
      width: 95%;
    }

    @media only screen and (max-width: 1424px) {
      background-size: contain;
      background-repeat: no-repeat;
      width: 95%;
    }

    @media only screen and (max-width: 1024px) {
      height: auto;
      top: 0;
      opacity: 0;
      min-height: 800px;
      margin-top: -60px;
      width: 100%;
    }

    @media only screen and (max-width: 800px) {
      min-height: 600px;
      width: 100%;
    }

    @media only screen and (max-width: 550px) {
      min-height: 375px;
      padding-top: 5%;
      width: 100%;
    }

    &.featureActive {
      left: 0 !important;
      opacity: 1 !important;
      z-index: 1 !important;
    }
  }
}

/*======================================================
                   Media Queries
  ======================================================*/

/* MOBILE */

@media only screen and (max-width: 576px) and (orientation: portrait) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 1024px) {
  .featureSelect {
    justify-content: center;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .featureSlider_panel .feature_slide {
    min-height: 450px;
  }

  .featuresWrapper {
    margin-top: 150px;
  }
}

/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .keyFeatures {
    padding-bottom: 50px;
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .featureSlider_panel .feature_slide {
    min-height: 500px;
  }

  .featuresWrapper {
    margin-top: 175px;
  }
}

/* Portrait */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .keyFeatures {
    padding-bottom: 75px;
  }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .featureSlider_panel .feature_slide {
    min-height: 600px;
  }
  .featuresWrapper {
    margin-top: 175px;
  }
}

/* Portrait */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .featureSlider_panel .feature_slide {
    min-height: 600px;
  }
  .featuresWrapper {
    margin-top: 175px;
  }
  .featureTitle {
    margin-top: 75px;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .keyFeatures {
    padding-bottom: 0px !important;
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .featureSlider_panel .feature_slide {
    min-height: 800px;
  }

  .featuresWrapper {
    margin-top: 250px;
  }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .featuresWrapper {
    margin-top: 250px;
  }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .keyFeatures {
    padding-bottom: 100px;
  }
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .keyFeatures {
    padding-bottom: 400px;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 834px) and (max-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .featuresWrapper {
    margin-top: 250px;
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .keyFeatures {
    padding-bottom: 300px;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .featuresWrapper {
    margin-top: 250px;
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .keyFeatures {
    padding-bottom: 450px;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
}

@media screen and (min-width: 1600px) {
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
}

/* FINE MOBILE */
