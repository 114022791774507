/*======================================================
                   General
  ======================================================*/

*:not(.container) {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
}

@media only screen and (max-device-width: 800px) {
  *:not(.container) {
    border: 0;
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
}

a {
}

@font-face {
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: 400;
  src: url(../components/assets/fonts/FoundersGrotesk-Regular.otf)
    format("opentype");
}

button,
input,
select,
textarea {
  font-family: inherit;
}
body {
  overflow-x: hidden;
  color: #222222;
  font-family: FoundersGrotesk;
  font-size: 16px;
  line-height: 22px;
  min-height: 100vh;
  width: 100vw;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
  padding-top: 60px;
}
body.no-scroll {
  overflow: hidden;
}

* {
  -webkit-tap-highlight-color: none;
}

section {
  margin-top: 200px;
}

.link {
  color: #666666;
}
.link:focus,
.link:hover {
  outline: none;
  text-decoration: none;
}

#closeMenu {
  /* top:0px;
  margin-top: 20px;
  margin-left: 5px;
  position: fixed; */
}

h1 {
  font-family: FoundersGrotesk;
  font-weight: 300;
  font-size: 42px;
  line-height: 1.3;
  width: 90%;
}

h2.main-title,
h2.secondtitle {
  padding-top: 0px;
  margin-bottom: 50px;
  font-size: 50px;
  font-family: FoundersGrotesk;
  line-height: 1;
}
h2.secondtitle {
  margin-top: 0px;
  margin-bottom: 65px;
  font-size: 40px;
  font-family: FoundersGrotesk;
  line-height: 1;
  font-weight: 800;
}
h2.main-title span,
h2.secondtitle span {
  color: #a2a5a8;
}

a.borderless-link-black {
  box-sizing: border-box;
}

a:hover.borderless-link-white {
  text-decoration: none;
  background-color: white;
  color: black;
}

a.borderless-link-white {
  box-sizing: border-box;
}

a:hover.borderless-link-black {
  text-decoration: none;
  border-bottom: 0.2em solid #cbcfd3;
  color: black;
}

.borderless-link-underlined-white {
  margin-bottom: 4em;
  border-bottom: 0.2em solid white;
  color: white;
}

.borderless-link-underlined-black {
  margin-bottom: 4em;
  border-bottom: 0.2em solid black;
  color: black;
}

.borderless-link-underlined-footer {
  margin-bottom: 4em;
  border-bottom: 0.2em solid #000000;
  color: white;
}

a:hover.borderless-link-underlined-footer {
  text-decoration: none;
  border-bottom: 0.2em solid #ffffff;
  color: ffffff;
}

/*Buttons*/

.button {
  background: #ffffff;
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: -0.01px;
  line-height: 22px;
  font-weight: 700;
  padding: 13px 18px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  -webkit-transition: color 0.15s ease-in-out, border 0.15s ease-in-out,
    background 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, border 0.15s ease-in-out,
    background 0.15s ease-in-out;
}

@media only screen and (max-device-width: 800px) {
  .button {
    padding: 10px 15px;
  }
}
.button:focus,
.button:hover {
  background: #000000;
  color: #ffffff;
  outline: none;
  border: 1px solid #ffffff;
}
.button-outline {
  padding-top: 12px;
  padding-bottom: 12px;
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
}

@media only screen and (max-width: 800px) {
  .button-outline {
    padding: 10px 14px;
  }
}
.button-outline:focus,
.button-outline:hover {
  background: #ffffff;
  color: #000000;
}

.button-outline-white {
  background: none;
  border: 1px solid #fff;
  color: #fff;
  background: #000000;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: -0.01px;
  line-height: 22px;
  font-weight: 700;
  padding: 13px 18px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  -webkit-transition: color 0.15s ease-in-out, border 0.15s ease-in-out,
    background 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, border 0.15s ease-in-out,
    background 0.15s ease-in-out;
}

@media only screen and (max-device-width: 800px) {
  .button-outline-white {
    padding: 10px 15px;
  }
}
.button-outline-white:focus,
.button-outline-white:hover {
  background: #ffffff;
  color: #000000;
  border: 1px solid #ffffff;
}

.button-outline-black {
  padding-top: 12px;
  padding-bottom: 12px;
  background: none;
  border: 1px solid #000000;
  color: #000000;
  background: none;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: -0.01px;
  line-height: 22px;
  font-weight: 700;
  padding: 13px 18px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  -webkit-transition: color 0.15s ease-in-out, border 0.15s ease-in-out,
    background 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, border 0.15s ease-in-out,
    background 0.15s ease-in-out;
}

@media only screen and (max-width: 800px) {
  .button-outline-black {
    padding: 10px 15px;
  }
}
.button-outline-black:focus,
.button-outline-black:hover {
  background: #000000;
  color: #ffffff;
  border: 1px solid #000000;
}

.button-icon {
  padding-left: 8px;
}
.text-button {
  background: none;
  color: inherit;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: -0.01px;
  line-height: 22px;
  font-weight: 700;
  padding: 0;
  display: flex;
  align-items: center;
  -webkit-transition: color 0.15s ease-in-out, border 0.15s ease-in-out,
    background 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, border 0.15s ease-in-out,
    background 0.15s ease-in-out;
}

@media screen and (min-width: 768px) {
  .half-width {
    width: calc(50% - 1rem) !important;
  }
}
input:focus,
textarea:focus {
  outline: none;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/*======================================================
                   Media Queries
  ======================================================*/

/* Extra small devices (very small phones under 350px) */
@media only screen and (max-width: 350px) {
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Media Queries */

/* MOBILE */

@media only screen and (max-width: 576px) and (orientation: portrait) {
  /*#page-title{
		display: none;
	}*/

  .menu ul.menu-list li {
    font-size: 5vh;
  }
  .menu ul.menu-list {
    padding-top: 15vh;
    padding-bottom: 15vh;
    height: 65vh;
  }
}

@media only screen and (max-width: 767px) {
  .hero {
    padding-top: 20px;
  }
  .menu {
    padding-top: 20px;
  }

  h2.bigtitle,
  h2.secondtitle {
    font-size: 28px;
    margin-bottom: 60px;
  }
  h2.secondtitle {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .menu ul.menu-list {
    margin-bottom: 5vh;
    padding-top: 15vh;
  }
  .menu ul.menu-list li {
    font-size: 40px;
  }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .container {
    width: 85%;
    padding-left: 15%;
  }

  .menu .info {
    display: none;
  }
}

/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  h2.bigtitle,
  h2.secondtitle {
    font-size: 28px;
    margin-bottom: 40px;
  }
}

/* Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .menu ul.menu-list li {
    float: left;
    margin-right: 5vw;
    font-size: 6vw;
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .menu .info {
    display: block;
  }
  .container {
    width: 90%;
    padding-left: 10%;
  }
}

/* Portrait */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .menu ul.menu-list {
    margin-bottom: 5vh;
  }
  .menu ul.menu-list li {
    font-size: 4vh;
    padding-bottom: 4vh;
  }
}

/* Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .menu ul.menu-list li {
    float: left;
    margin-right: 5vw;
    font-size: 6vw;
  }
  .menu ul.menu-list li {
    margin-right: 6vw;
    font-size: 5vw;
  }
  .menu ul.menu-list {
    padding-top: 23vh;
  }
  .menu .info {
    display: none;
  }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .menu ul.menu-list li {
    float: left;
    margin-right: 5vw;
    font-size: 6vw;
  }
  .menu ul.menu-list li {
    margin-right: 6vw;
    font-size: 5vw;
  }
  .menu ul.menu-list {
    padding-top: 23vh;
  }
  .menu .info {
    display: none;
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .container {
    width: 90%;
    padding-left: 10%;
  }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .featureSlider_panel .feature_slide {
    padding-bottom: 60%;
  }
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .menu ul.menu-list {
    margin-bottom: 0vh;
  }
  .menu ul.menu-list li {
    font-size: 5vh;
    padding-bottom: 4vh;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 834px) and (max-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .menu ul.menu-list {
    margin-bottom: 0vh;
  }
  .menu ul.menu-list li {
    font-size: 5vh;
    padding-bottom: 4vh;
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .menu ul.menu-list {
    padding-top: 15vh;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
}

@media screen and (min-width: 1600px) {
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
}

/* FINE MOBILE */

/*======================================================
                   Bootstrap Min
  ======================================================*/

.section {
  padding-top: 48px;
  padding-bottom: 48px;
}
@media only screen and (min-width: 576px) {
  .section {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
@media only screen and (min-width: 992px) {
  .section {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

@media only screen and (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
