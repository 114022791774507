@font-face {
    font-family: FoundersGrotesk;
    font-style: normal;
    font-weight: 400;
    src: url(../components/assets/fonts/FoundersGrotesk-Regular.otf)
      format("opentype");
  }
  
  $colorMain: #ffffff;
  $colorSecondary: #c7cdd6;
  $colorLight: #cbcfd3;
  $colorDark: #000000;
  
  $breakpointM: 700px;
  
  /*======================================================
                     Getting Started Slider 
    ======================================================*/

    #setup {
     margin-top:15%;

     @media only screen and (max-width:800px){
       margin-top:30%;
     }
    }
  
  .slider {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    min-height: 270px;
  
    @media only screen and (max-width: $breakpointM) {
      height: 33vh;
    }
  }
  
  .slider__panel {
    position: relative;
    min-height: 428px;
    height: inherit;
  
    @media only screen and (max-device-width: 500px) {
      min-height:0px;
    }
  
    &.slider__panel__controls {
      padding-right: 75px;
      overflow: auto;
      width: 45%;
  
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding-right: 0px;
        overflow:visible;
      }
  
      .feature {
        font-size: 20px;
        letter-spacing: -0.01px;
        line-height: 28px;
        font-weight: 700;

        @media only screen and (max-width: 800px) {
          font-size: 16px;
        }

        @media only screen and (max-width: 1023px) {
          text-align: center;
          font-size:18px;
        }
      }
  
      .title {
        font-size: 36px;
        letter-spacing: -0.04px;
        line-height: 50px;
        font-weight: 700;
        padding-top: 16px;
        flex: 0.4 1;
        padding-bottom:30px;

        @media only screen and (max-device-width:1024px){
          text-align: center;
        }

        @media only screen and (max-device-width:1280px){
          font-size:30px;
        }


        /* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  font-size: 24px;
  letter-spacing: -0.03px;
  line-height: 32px;
  padding-top: 20px;
  height: auto;
  text-align: center;

}

        /* Portrait */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  font-size: 24px;
  letter-spacing: -0.03px;
  line-height: 32px;
  padding-top: 20px;
  height: auto;
  text-align: center;

}

/* Portrait */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  font-size: 24px;
  letter-spacing: -0.03px;
  line-height: 32px;
  padding-top: 20px;
  height: auto;
  text-align: center;
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  font-size: 24px;
  letter-spacing: -0.03px;
  line-height: 32px;
  padding-top: 20px;
  height: auto;
  text-align: center;
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  font-size: 30px;
  letter-spacing: -0.03px;
  line-height: 32px;
  padding-top: 20px;
  height: auto;
  text-align: center;
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  font-size: 30px;
  letter-spacing: -0.03px;
  line-height: 32px;
  padding-top: 20px;
  height: auto;
  text-align: center;
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  font-size: 30px;
  letter-spacing: -0.03px;
  line-height: 32px;
  padding-top: 20px;
  height: auto;
  text-align: center;
}
      }
  
      .select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 300px;
        margin: 50px 30% 50px 0;
        font-family: FoundersGrotesk;
        font-size: 18px;
        font-weight: bold;
        color: $colorDark;

        @media only screen and (max-width: 1023px) {
            margin:auto;
            margin-top:25px;
            margin-bottom:25px;
          }

  
        .select__item {
          $wdDash: 12px;
          position: relative;
          min-width: $wdDash;
          margin: 0;
          text-align: center;
          opacity: 1;
          color: #cbcfd3;
          cursor: pointer;
  
          &::before {
            content: "";
          }
  
          &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            left: calc(50% - #{$wdDash / 2});
            width: 0;
            height: 2.5px;
            background: $colorMain;
            opacity: 0;
            transition: all 0.4s ease;
          }
  
          &:hover::after {
            width: $wdDash;
            background: $colorDark;
            opacity: 1;
          }
  
          &.active {
            opacity: 1;
            color:#000000;
  
            &::after {
              width: $wdDash;
              opacity: 1;
            }
          }
        }
      }
  
      .description {
        margin: 0;
        color:#7e8794;
        font-size: 20px;
        letter-spacing: -0.01px;
        line-height: 30px;
        text-align: left;
        flex-shrink: 0;
        transition: color 0.15s ease-in-out, border 0.15s ease-in-out;

        @media only screen and (max-width: 1023px) {
          text-align: center;
          font-size:18px;
          line-height:26px;
          padding-top:30px;
        }

                /* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

}

        /* Portrait */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  
}

/* Portrait */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  

}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {


}
      }
    }
  
    &.slider__panel__frame {
      padding: 20px;
      box-shadow: 0 4px 17px 0 rgba(50, 54, 72, 0.18);
      overflow: hidden;
      width:55%;

      @media only screen and (max-device-width:1024px) and (max-width:1024px){
      width: 100%;
      margin-top:-50px;
      }

      @media only screen and (max-device-width:585px) and (max-width:585px){
        margin-top:0px;
        width:100%
      }
    
      @media only screen and (max-device-width:500px) and (max-width:500px){
        margin-top: 150px;
        width:100%;
      }
      /* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  margin:auto;
  width:90%;
  display: inline-block;
  position: relative;
  margin-top:-200px;
}


      /* Portrait iPhone 5 */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  width: 100%;
  padding: 0px;
  margin-top: 100px;
}
  
      /* Portrait iPhone 6-8 */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  margin-top: 75px;
}

/* Portrait iPhone X */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  margin-top: 30px;
}

      .slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        opacity: 0;
        z-index: 0;
        transition: all 0.5s fade;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size:contain;
  
        @media only screen and (max-device-width: 800px) {
      
        }
  
        &.active {
          left: 0 !important;
          opacity: 1 !important;
          z-index: 1 !important;
        }
      }
    }
  }
  
  /*======================================================
                     OTHER
    ======================================================*/
  
  .runningBorderless {
    width: 90%;
    margin: auto;
  }
  
  .setupWrapper {
    max-width: 1400px;
    margin: auto;
  }
  
  .marketCapSection {
    width: 90%;
    margin: auto;
  }
  
  @media only screen and (max-device-width: 800px) {
    .runningBorderless {
      width: 90%;
      margin: auto;
    }
  }
  
  .setupWrapper {
    max-width: 1400px;
    margin: auto;
  }
  
  .marketCapSection {
    width: 90%;
    margin: auto;
  }
  

   /*======================================================
                   Media Queries
  ======================================================*/

/* MOBILE */

@media only screen and (max-width: 576px) and (orientation: portrait) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 1024px) {
.select{
  margin:auto;
  margin-top:25px;
  margin-bottom:25px;
}
}

@media only screen and (max-width: 1280px) {
  .slider__panel .slider__panel__controls .title{
font-size:30px;
  }
  }

@media screen and (min-width: 1200px) and (max-width: 1600px) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  #multichaincomparison {
    margin-top:150px;
  }
}

/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

}

/* Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  #multichaincomparison {
    margin-top:150px;
  }

}

/* Portrait */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

}

/* Landscape */
@media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {

}

/* Portrait */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {

}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {

}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

}


/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .slider__panel__controls {
      width: 100%;
      overflow:visible;
    }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .slider__panel__controls {
    width: 100%;
    overflow:visible;
  }
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .slider__panel__controls {
    width: 100%;
    overflow:visible;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 834px) and (max-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .slider__panel__controls {
    width: 100%;
    overflow:visible;
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .slider__panel__controls {
    width: 100%;
    overflow:visible;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .slider__panel__controls {
    width: 100%;
    overflow:visible;
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .slider__panel__controls {
    width: 100%;
    overflow:visible;
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .slider__panel__controls {
    width: 100%;
    overflow:visible;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .slider__panel__controls {
    width: 100%;
    overflow:visible;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media screen and (min-width: 1600px) {
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
}

/* FINE MOBILE */